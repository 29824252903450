import { Component, OnInit, ChangeDetectorRef, OnDestroy } from '@angular/core';
import { Router } from '@angular/router';
import { SitemapService } from '../../shared/services/sitemap.service';
import { Role } from 'src/app/shared/enums/role.enum';
import { Constants } from 'src/app/shared/constants/constant';
import { ServerApisService } from 'src/app/shared/services/server-apis.service';
import { NgxSpinnerService } from 'ngx-spinner';
import { RouteMakingService } from 'src/app/shared/services/route-making.service';
import { ToastrService } from 'ngx-toastr';
import { Subject } from 'rxjs';
import { debounceTime } from 'rxjs/operators';

@Component({
  selector: 'app-admin-sitemap',
  templateUrl: './admin-sitemap.component.html',
  styleUrls: ['./admin-sitemap.component.css']
})
export class AdminSitemapComponent implements OnInit, OnDestroy {
  message: string = '';
  messageClass: string;
  urls: any = [];
  adminPanel: string = Constants.adminPanel;
  adminSitemap: string = Constants.adminSitemap;
  adminPanelRoute: string = Constants.adminPanelRoute;
  adminSitemapTitle: string = Constants.adminSitemapTitle;
  sitemapData: any;
  p = 1;

  lstSitemapList: any[] = [];
  isEditIndex: number;
  count: number;
  lsttempSitemapList: any[] = [];
  searchTerm: string = ''; 
  selectedSitemapOption: string = '';
  temp: any;
  searchedSitemapID: string;
  searchedCategoryName: string;
  searchedLoc: string = '';
  btnDisable: boolean;
  fromDate: string = '';
  toDate: string = '';
  private searchSubject: Subject<string> = new Subject();

  constructor(
    private sitemapService: SitemapService,
    private router: Router,
    private spinner: NgxSpinnerService,
    private serviceApi: ServerApisService,
    private routeMakingService: RouteMakingService,
    private changeDetectorRef: ChangeDetectorRef,
    private toastr: ToastrService,
  ) { }

  ngOnInit(): void {
    this.fromDate = this.formatDateString(new Date(new Date().setDate(new Date().getDate() - 10)));
    this.toDate = this.formatDateString(new Date());

    this.changeDetectorRef.detectChanges();

    const user = sessionStorage.getItem('user');
    if (user != null) {
      let data = JSON.parse(user);
      if (data.roleId !== Role.Admin && data.roleId !== Role.SeoTeam && data.roleId !== Role.SubAdmin) {
        this.router.navigate([""]);
      } else {
        this.getAllSiteMapList();
      }
    } else {
      this.router.navigate([this.routeMakingService.getNavigateUrl([Constants.Login])]);
    }

    this.searchSubject.pipe(debounceTime(500)).subscribe((searchText) => {
      this.searchedLoc = searchText;
      this.getAllSiteMapList();
    });
  }

  ngOnDestroy(): void {
    this.searchSubject.unsubscribe();
  }

  scrollToTop() {
    window.scrollTo(0, 0);
  }

  generateSitemap() {
    this.toastr.info('Sitemap is generating, Please wait for approx 10 to 15 mins', '', {
      timeOut: 3000,
      progressBar: true,
      progressAnimation: 'decreasing',
      closeButton: true,
    });
    this.btnDisable= true;
    this.sitemapService.SiteMapForProfileData().subscribe(
      (response) => {
        this.urls = response.SiteMapForProfileDataResult.url;
        this.btnDisable= false;
        this.toastr.success('Sitemap.xml has been generated successfully!', '', {
          timeOut: 3000,
          progressBar: true,
          progressAnimation: 'decreasing',
          closeButton: true,
        });
      },
      (error) => {
        this.btnDisable= false;
        this.toastr.error('Unable to generate sitemap.xml, please contact the administrator!', '', {
          timeOut: 3000,
          progressBar: true,
          progressAnimation: 'decreasing',
          closeButton: true,
        });
      }
    );
  }

  generateSearchSitemap() {
    this.toastr.info('Sitemap is generating, Please wait for approx 10 to 15 mins', '', {
      timeOut: 3000,
      progressBar: true,
      progressAnimation: 'decreasing',
      closeButton: true,
    });
    this.btnDisable= true;
    this.sitemapService.getSearchSiteMapFtpFileTransfer().subscribe(
      (response) => {
        this.btnDisable= false;
        this.toastr.success('Search sitemap.xml has been generated successfully!', '', {
          timeOut: 3000,
          progressBar: true,
          progressAnimation: 'decreasing',
          closeButton: true,
        });
      },
      (error) => {
        this.btnDisable= false;
        this.toastr.error('Unable to generate search sitemap.xml, please contact the administrator!', '', {
          timeOut: 3000,
          progressBar: true,
          progressAnimation: 'decreasing',
          closeButton: true,
        });
      }
    );
  }

  getAllSiteMapList() {
    this.spinner.show();
    let payLoad = { 
      loc: this.searchedLoc, 
      pageNo: this.p,
      fromDate: this.fromDate,
      toDate: this.toDate,
    };

    this.serviceApi.getAllSiteMapList(payLoad).subscribe(
      (res: any) => {
        this.spinner.hide();
        if (res.FetchAllSiteMapListResult && res.FetchAllSiteMapListResult.status === 200) {
          this.lstSitemapList = res.FetchAllSiteMapListResult.resultFetchSiteMap;
          if (this.lstSitemapList.length > 0) {
            this.count = +this.lstSitemapList[0].RecordCount;
            this.lsttempSitemapList = this.lstSitemapList;
            this.scrollToTop();
          } else {
            this.count = 0;
          }
        } else {
          console.error('API response not as expected:', res);
        }
      },
      (error) => {
        this.spinner.hide();
        console.error('Error fetching sitemap data:', error);
      }
    );
  }

  formatDate(date: Date): string {
    const day = date.getDate().toString().padStart(2, '0');
    const month = (date.getMonth() + 1).toString().padStart(2, '0');
    const year = date.getFullYear();
    return `${year}-${month}-${day}`;
  }

  formatDateString(date: Date): string {
    return this.formatDate(date);
  }

  searchedByLoc(event: any) {
    const searchText = event.target.value;
    this.searchSubject.next(searchText);
  }

  resetSitemapOptions() {
    this.searchedSitemapID = '';
    this.searchedCategoryName = '';
    this.searchedLoc = '';
  }

  onDateChange(event: any, type: string) {
    const value = event.target.value;
    if (type === 'fromDate') {
      this.fromDate = value;
    } else if (type === 'toDate') {
      this.toDate = value;
    }
    this.getAllSiteMapList();
  }

  getAllUrls(): string {
    return this.urls.join('\n');
  }

  pageChanged(event: any) {
    this.p = event;
    this.isEditIndex = -1;
    this.getAllSiteMapList();
  }
}
