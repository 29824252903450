import { Component, OnInit,AfterViewInit,OnChanges, ChangeDetectorRef,ViewChild, Output, EventEmitter,ElementRef, Renderer2,OnDestroy} from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { OwlOptions } from 'ngx-owl-carousel-o';
import { AlertService } from 'src/app/shared/alert/alert.service';
import { ServerApisService } from '../../shared/services/server-apis.service';
import { NgxSpinnerService } from 'ngx-spinner';
import { DataService } from 'src/app/shared/services/data.service';
import { Observable } from 'rxjs';
import {UserProfileObject} from '../../shared/Interfaces/user-profile-obj-interface';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
import { MatDialog } from '@angular/material/dialog';
import 'firebase/auth';
import { GoogleApisService } from '../../shared/services/google-apis.service'
import firebase from 'firebase/compat/app';
import { NgZone } from '@angular/core';
import { ReviewComponent } from '../review/review.component';
import { UpdateMobileNumberComponent } from 'src/app/Core/update-mobile-number/update-mobile-number.component';
import { PropsService } from 'src/app/shared/services/props.service';
import { Title, Meta } from '@angular/platform-browser';
import 'owl.carousel';
import { SearchService } from 'src/app/shared/services/search.service';
import { ImageValidationService } from 'src/app/shared/services/image-validation.service';
declare const $ : any;
@Component({
  selector: 'app-provider-profile',
  templateUrl: './provider-profile.component.html',
  styleUrls: ['./provider-profile.component.css','./provider-profile2.component.css'],
})
// @Component({
//   selector: 'app-review',
//   templateUrl: './review.component.html',
//   styleUrls: ['./review.component.css'],
// })
export class ProviderProfileComponent implements OnInit,AfterViewInit,OnChanges,OnDestroy {
  @Output() setAddress: EventEmitter<any> = new EventEmitter();
  origin: {};
  locationDisplay: boolean = true;
  isUserProfile = false;
  isProfileEdit = false;
  flag$: Observable<boolean>;
  toskieVerified ;
  professionVerified ;
  businessVerified ;
  userActivation : any;
  QueryParamId: string;
  providerInfo: any = {};
  imageObject: Array<object> = [];
  defaultImage: Array<object> = [];
  services: any = [];
  ratingAndReview: any = [];
  display = 'none';
  PrimaryService: any;
  phoneNumber: any;
  image: any = null;
  breadData1: string = "Search";
  breadData2: string = "Profile";
  breadData3: string = "";
  breadData4: string = "Profile";
  storedData = sessionStorage.getItem('searchData');
  searchData = this.storedData != "" ? JSON.parse(this.storedData) != null || JSON.parse(this.storedData) != undefined ? JSON.parse(this.storedData) : {} : {};
  breadRouteData1: string = `/${sessionStorage.getItem('parentCategory')?.replace(/ /g, '-')?.toLowerCase()}/${sessionStorage.getItem('childCategory')?.replace(/ /g, '-')?.toLowerCase()}/${sessionStorage.getItem('locality')?.replace(/ /g, '-')?.toLowerCase()}`;
  breadRouteData2: string = "";
  imagetext: any;
  recaptchaVerifier: firebase.auth.RecaptchaVerifier;
  memberId = sessionStorage.getItem('memberId');
  memberIdFromURL;
  country="india";
  cntrycode="+91";
  otpsent: boolean = false;
  customOptions: OwlOptions = {
    loop: false,
    mouseDrag: true,
    touchDrag: true,
    pullDrag: true,
    dots: true,
    navSpeed: 700,
    navText: [
      '<i class="ti-angle-left"></i>',
      '<i class="ti-angle-right"></i>',
    ],
    nav: true
  }
  isProviderProfile: boolean = true;
  active: boolean;
  addressEdit: boolean;
  userAddress: string;
  editedAddressValue: string;
  descriptionEdit: boolean;
  userDescription: any;
  skillsEdit: boolean;
  editedUserDescription: any;
  mobileEdit: boolean;
  emailEdit: boolean;
  userMobile: any;
  userEmail: any;
  editedMobileNumber: any;
  editedEmailAddress: any;
  editedDateOfBirth: any;
  userProfileObj: UserProfileObject;
  blob: any;
  organizationEdit: boolean;
  userOrganizationValue: any;
  editedOrgValue: any;
  editedFName: any;
  userFName: any;
  lnameEdit: boolean;
  editedLName: any;
  userLName: any;
  fnameEdit: boolean;
  dateOfBirthEdit: boolean;
  dateOfBirthValue:any = '';
  youtubeUrl = '';
  isAddPhotos: boolean;
  ImgValidate: boolean;
  imageChangedEvent: any = '';
  selectedFile: any;
  imagePath: any;
  extn: string;
  uniqueId:string;
  imgURL: any;
  selectedGender: string = ''; 
  profileUrl: string ;
  userInfo: any = {};
  isShareModalOpen: any = false;
  userOrganizationValueChanged: boolean;
  verificationResult: firebase.auth.ConfirmationResult;
  phno: string;
  userDateOfBirth: any;
  profileImageUrl: any;
  imageValidated: boolean = true;
  photoEdit: boolean;
  profileImageNotFound: any = false;
  youtube : boolean = false;
  notVerified : boolean = false;
  userData: any = sessionStorage.getItem('user') !== null ? JSON.parse(sessionStorage.getItem('user')) : '';
  uniqueID: string = this.userData !== '' ? this.userData.UserUniqueCode : '';
  autoCompleteOptions = {
    types: ['address']
  };
  mobileInvalidMessage: any;
  mobilenumVerified: boolean;
  pageName: string = 'profile';
  @ViewChild(ReviewComponent) reviewComponent: ReviewComponent;
  photo: any;
  addressobj: any = {};
  localAddress: any;
  lstCountry: any;
  selectedcountry: any;
  countryID: any;
  progressValue: any = 0;
  remainingFields: any;
  showGif: boolean = false;
  showContactNo: string;
  loggedIn: boolean;
  inProgress: boolean = false;
  authorizatonToken: string;
  call_id: string;
  description: string;
  metaDescription: any;
  metaTitle: string;
  @ViewChild('nav') slider: any; // Reference to the ng-image-slider component
  middleIndex: number;
  intervalId: any;
  minDateOfBirth: string = '1900-01-01'; 
  maxDateOfBirth: string = '2014-12-31';
  remainingCharacters: number;
  overflowText: string = '';
  removeCharactersCount: number;
  countryCode: any;
  country_id: any;
  providerImageValidated: boolean = true;
  customButtons: any = [];

  constructor(private googleApi:GoogleApisService,private zone: NgZone,private cdr: ChangeDetectorRef,public dialog: MatDialog,private route: ActivatedRoute, private router: Router, private _apiService: ServerApisService,
    private _alertService: AlertService, private spinner: NgxSpinnerService,private dataService:DataService,private sanitizer: DomSanitizer,private propsService: PropsService,private renderer: Renderer2,
    private el: ElementRef,private metaService:Meta,private titleService:Title,private searchService:SearchService,
    private imageValidationService: ImageValidationService) {
      this.userProfileObj = {
        memberId: 0,
        mobile: '',
        fname: '',
        lname: '',
        longitude: '',
        latitude: '',
        fileName: '',
        profileDescription: '',
        email: '',
        countryCode: '',
        fullAddress: '',
        UPImobile: '',
        token: '',
        country_id: 0,
        organisationName: '',
        state_name: '', 
        pinCode: '',    
        dateOfBirth: '', 
        gender: '',     
        deviceType : 'web'
      };  
  }

  ngAfterViewInit() {
    let pathname = window.location.pathname;
    const parts = pathname.split('-');
    if (parts.length > 1) {
      const numbersAfterHyphen = parseInt(parts[parts.length - 1], 10);
      
      this.memberIdFromURL = numbersAfterHyphen;
    }
    if (this.memberIdFromURL == this.memberId) {
      this.isProviderProfile = false;
    } else {
      this.isProviderProfile = true;
    }
    if (this.otpsent) {
      this.initializeRecaptcha();
    }
    this.uniqueID = this.userData !== '' ? this.userData.UserUniqueCode : '';
    $(".carousel_2").owlCarousel({
      nav: true,
      loop: true,
      items: 3,
      margin: 15,
      center: true,
    }).on('initialized.owl.carousel linked.to.owl.carousel', () => {
      this.updateCurrentClass();
    });
  }

  private updateCurrentClass(): void {
    const sync2 = $(".carousel_2");
    sync2.find('.owl-item.current').removeClass('current');
    const current = sync2.find('.owl-item.active.center').length
      ? sync2.find('.owl-item.active.center')
      : sync2.find('.owl-item.active').eq(0);
    current.addClass('current');
    this.cdr.detectChanges();
  }

  ngOnChanges() {
    let pathname = window.location.pathname;
    const parts = pathname.split('-');
    if (parts.length > 1) {
      const numbersAfterHyphen = parseInt(parts[parts.length - 1], 10);

      this.memberIdFromURL = numbersAfterHyphen;
    }
    if (this.memberIdFromURL == this.memberId) {
      this.isProviderProfile = false;
    } else {
      this.isProviderProfile = true;
    }
    if (this.otpsent) {
      this.initializeRecaptcha();
    }
    this.viewProviderProfileBYURL(this.QueryParamId); 
    if(this.providerInfo.OrganisationImages.length > 0){
      this.defaultImage = [];
    } else {
      this.defaultImage = [
        {
          image: 'assets/images/default-image.png',
          thumbImage: 'assets/images/default-image.png',
          alt: 'default image',
          title: 'default image',
        },
      ];
    }
    this.middleIndex = Math.floor(this.imageObject.length / 2);

    // Manually add a class to the middle image after the view has been initialized
    const imageElements = this.slider.images;
    
    if (imageElements && imageElements.length > this.middleIndex) {
      imageElements[this.middleIndex].classList.add('middle-image');
    }
  }

  ngOnInit(): void {
    this.pollApi();
    this.profileCompletionStatus();
   
    this.flag$ = this.dataService.currentFlag;
    this.flag$.subscribe((res)=>{
      this.isUserProfile = res;
    })

    this.route.queryParamMap.subscribe((URLparams: any) => {
      this.route.params.subscribe(params => {
        const parts = params['name-:id'].split('-'); // Split by hyphen
       // console.log(parts);
        
        if (parts.length > 1) {
          this.QueryParamId = parts[parts.length - 1];
        }
      });
    });

    if(this.providerInfo?.OrganisationImages?.length > 0){
      this.defaultImage = [];
    } else {
      this.defaultImage = [
        {
          image: 'assets/images/default-image.png',
          thumbImage: 'assets/images/default-image.png',
          alt: 'default image',
          title: '',
        },
      ];
    }

    this.viewProviderProfileBYURL(this.QueryParamId);
    // this.getAllReviewAndRatings(this.QueryParamId);
    window.scrollTo(0, 0)

    let memberID = sessionStorage.getItem('memberId') ? sessionStorage.getItem('memberId') : 0;
    let token = sessionStorage.getItem('token') != null ? sessionStorage.getItem('token') : '';
    
    this._apiService.GetUserActivateStatus(memberID,this.QueryParamId,token).subscribe((res:any)=>{
      this.userActivation = res;
      this.toskieVerified = this.userActivation.GetUserProfileInformationResult.resultUserProfileInfo.isAccountVerified;
      this.professionVerified = this.userActivation.GetUserProfileInformationResult.resultUserProfileInfo.isProfessionVerified;
      this.businessVerified = this.userActivation.GetUserProfileInformationResult.resultUserProfileInfo.isBusinessVerified;
      this.youtubeUrl= this.userActivation.GetUserProfileInformationResult.resultUserProfileInfo.profileVideoUrl;
      this.setSafeYoutubeUrl(this.youtubeUrl);
      this.toskieVerified === 'Y' ? this.toskieVerified = true : this.toskieVerified = false;
      this.professionVerified === 'Y' ? this.professionVerified = true : this.professionVerified = false;
      this.businessVerified === 'Y' ? this.businessVerified = true : this.businessVerified = false;
      this.active = this.userActivation.GetUserProfileInformationResult.resultUserProfileInfo.isActive == 'Y' ? true : false;
      if(this.toskieVerified === false && this.professionVerified === false && this.businessVerified === false){
        this.notVerified = true;
      }
      //profile pic from new API
      this.profileImageUrl = this.userActivation.GetUserProfileInformationResult.resultUserProfileInfo.profileImageUrl;
      if (this.profileImageUrl.includes('null') || this.profileImageUrl === '') {
        this.profileImageNotFound = true;
        this.profileImageUrl = '';
        this.imageValidated = false;
      } else {
        this.profileImageNotFound = false;
        this.imageValidationService.validateImage(this.profileImageUrl).subscribe(
          isValid => {
            this.imageValidated = isValid;
          },
          error => {
            this.imageValidated = false;
          }
        );
      }
      this.countryCode = res.GetUserProfileInformationResult.resultUserProfileInfo.countryCode;
      this.country_id = res.GetUserProfileInformationResult.resultUserProfileInfo.country_id;

      //gender setting
      this.selectedGender = this.userActivation.GetUserProfileInformationResult.resultUserProfileInfo.gender;
      this.customButtons = this.userActivation.GetUserProfileInformationResult.resultUserProfileInfo.profileCustomButtons;
      this.customButtons = this.customButtons.filter((button) => button.customButonName !== '' && button.customButonIsActive === 'Y');
      sessionStorage.setItem('providerProfile',JSON.stringify(this.userActivation.GetUserProfileInformationResult.resultUserProfileInfo));
    })
    this.getCurrentLocation();
    this.getCountries();
    this.propsService.getProfileCompeletion().subscribe((res:any)=>{
      this.remainingFields = res;
    });

    if (sessionStorage.getItem('authTokenCall') !== null && sessionStorage.getItem('authTokenCall') !== undefined && sessionStorage.getItem('authTokenCall') !== "" && sessionStorage.getItem('currentCallId') !== null && sessionStorage.getItem('currentCallId') !== undefined && sessionStorage.getItem('currentCallId') !== ""){
      this.authorizatonToken = sessionStorage.getItem('authTokenCall');
      this.call_id = sessionStorage.getItem('currentCallId');
      
      if(this.call_id !== null && this.call_id !== undefined && this.call_id !== "" && this.authorizatonToken !== null && this.authorizatonToken !== undefined && this.authorizatonToken !== ""){
        this._apiService.CheckCallRecords(this.call_id, this.authorizatonToken).subscribe((res: any) => {
          if (res.results.length === 0) {
            this.inProgress = true;
          } else {
            this.inProgress = false; 
          }
        }, (error) => {
          console.log(error);
        });
      }
    }
  }

  openButtonUrl(url: string): void {
    const dialogWidth = 900; 
    const dialogHeight = 650; 
    const left = (window.screen.width / 2) - (dialogWidth / 2);
    const top = (window.screen.height / 2) - (dialogHeight / 2);
  
    window.open(
      url, 
      'newwindow', 
      `width=${dialogWidth},height=${dialogHeight},top=${top},left=${left}`
    );
  }
  dateOfBirthRangeValidator(control) {
    const selectedDate = new Date(control);
    const minDate = new Date('1900-01-01');
    const maxDate = new Date('2014-12-31');

    if (selectedDate < minDate || selectedDate > maxDate) {
      return false;
    }

    return true;
  }


  pollApi(): void {
    if (sessionStorage.getItem('authTokenCall') !== null && sessionStorage.getItem('authTokenCall') !== undefined && sessionStorage.getItem('authTokenCall') !== "" && sessionStorage.getItem('currentCallId') !== null && sessionStorage.getItem('currentCallId') !== undefined && sessionStorage.getItem('currentCallId') !== ""){
      this.authorizatonToken = sessionStorage.getItem('authTokenCall');
      this.call_id = sessionStorage.getItem('currentCallId');
      
      if(this.call_id !== null && this.call_id !== undefined && this.call_id !== "" && this.authorizatonToken !== null && this.authorizatonToken !== undefined && this.authorizatonToken !== ""){
        this._apiService.CheckCallRecords(this.call_id, this.authorizatonToken).subscribe((res: any) => {
          if (res.results.length === 0) {
            this.inProgress = true;
          } else {
            this.inProgress = false; 
          }
        }, (error) => {
          console.log(error);
        });
      }
    }
  }

  ngOnDestroy(): void {
    // Clear the interval when the component is destroyed
    if (this.intervalId) {
      clearInterval(this.intervalId);
    }
    $(".carousel_2").owlCarousel('destroy');
  }

  descriptionAdd(){
    this.description = `Greetings! My name is ${this.providerInfo.fname}, and I am a ${this.PrimaryService} based 
    in ${this.providerInfo.fullAddress}. With profound expertise in my field, I pride myself on delivering exceptional services tailored to your needs.
    In this competitive professional landscape, I excel by leveraging my in-depth industry knowledge and utilizing top-notch products. My goal is to create impeccable 
    results that meet and exceed your expectations. I am well aware of the unique challenges you may encounter, 
    and I am dedicated to crafting personalized solutions for all your requirements.
    I am not just a service provider; I am a seasoned professional committed to standing out in the industry. 
    I am eager to connect with you and discuss how I can assist you further. Looking forward to our collaboration!`

    this.metaDescription = `Greetings! My name is ${this.providerInfo.fname} ${this.providerInfo.lname}, and I am a ${this.PrimaryService} based 
    in ${this.providerInfo.fullAddress}.`
    this.metaTitle = `${this.providerInfo.fname} ${this.providerInfo.lname} - ${this.PrimaryService}`;
  }

  addMetaTags():void {
      this.titleService.setTitle('Toskie - Profile');
      this.metaService.updateTag({title: 'title', content: 'Toskie - Profile' });
      this.metaService.updateTag({name: 'description', content: this.metaDescription });
      this.metaService.updateTag({property : 'og:description', content: this.metaDescription });
      this.metaService.updateTag({property : 'og:image', content: this.image });
      this.metaService.updateTag({property : 'og:title', content: this.metaTitle })
      this.metaService.updateTag({ name: 'twitter:card', content: 'summary_large_image' });
      this.metaService.updateTag({ name: 'twitter:title', content: this.metaTitle });
      this.metaService.updateTag({ name: 'twitter:description', content: this.metaDescription });
      this.metaService.updateTag({ name: 'twitter:image', content: this.image });
      // this.searchService.updateCanonicalLink(window.location.href);
  }


  profileCompletionStatus(){
    const requestBodySubmit = {
      MemberID: this.memberId
    }; 
    this._apiService.CompleteProfileStatus(requestBodySubmit).subscribe((res:any)=>{
      this.propsService.setProfileCompeletion(res.CompleteProfile);
      let totalField = 9;
      let totalFieldVacant = res.CompleteProfile.length;
      let onePart = 100 / totalField;
      if (res.CompleteProfile.length == 0) {
        this.progressValue = 100;
      } else {
        let totalFieldFilled = totalField - totalFieldVacant;
        this.progressValue = Number(onePart * totalFieldFilled).toFixed(2);
      }
    })
  }

  getCountries() {
    this._apiService.GetCountries().subscribe(res => {
      this.lstCountry = res['GetAllCountriesResult'].resultCountries;
      this.selectedcountry = this.lstCountry[1].country_name;
      this.countryID = this.lstCountry[1].country_id;
      this.userProfileObj.country_id = this.lstCountry[1].country_id;
    });
  }

  getCurrentLocation() {
    navigator.geolocation.getCurrentPosition((position) => {
      this.addressobj['latlng'] = position.coords.latitude + ',' + position.coords.longitude;
      this.origin = {};
      this.origin['lat'] = position.coords.latitude;
      this.origin['lng'] = position.coords.longitude;
      this.googleApi.originLatLng(this.origin);
      this.getAddress(this.addressobj)
    });
  }

  connectWithWhatsapp() {
    const phoneNumber = '9108069179999';
    const message = encodeURIComponent('Hello Toskie');
    const whatsappUrl = `https://wa.me/${phoneNumber}?text=${message}`;
    
    window.open(whatsappUrl, '_blank');
  }
  
  getAddress(a) {
    this.googleApi.getCurrentGeoAddress(a).subscribe(res => {
      // let formatAddress = res.results[0].formatted_address
      // this.userAddress = formatAddress;
      this.setAddress.emit(this.localAddress);
      res.results[0].address_components;
      for (let i = 0; i < res.results[0].address_components.length; i++) {
        const element = res.results[0].address_components[i];
        for (let m = 0; m < element.types.length; m++) {
          const ele = element.types[m];
          if (ele == "postal_code") {
            this.userProfileObj.pinCode = element.long_name;
          }
          if (ele == "administrative_area_level_1") {
            this.userProfileObj.state_name = element.long_name;
          }
        }
      }
      this.userProfileObj.latitude = res.results[0].geometry.location.lat;
      this.userProfileObj.longitude = res.results[0].geometry.location.lng;
      // this.userProfileObj.fullAddress = this.localAddress != undefined ? this.localAddress : ;
    },
      error => { console.log(error) })
  }

  getLocationAddressCoordinates(address: any) {
    this.origin = {};
    if (typeof address !== 'string') {
      this.localAddress = address.formatted_address;
      this.origin['lat'] = address.geometry.location.lat();
      this.origin['lng'] = address.geometry.location.lng();
      this.googleApi.originLatLng(this.origin);
    } else {
      this.localAddress = [address];
    }
    this.zone.run(() => {
      this.localAddress = typeof this.localAddress !== 'string' ? this.localAddress[0] : this.localAddress;
      this.addressobj['latlng'] = this.origin['lat'] + ',' + this.origin['lng'];
      this.getAddress(this.addressobj);
    });
  }

  removePhoto(index: number): void {
    if (this.providerInfo && this.providerInfo.OrganisationImages && index >= 0 && index < this.providerInfo.OrganisationImages.length) {
        let payLoad:any={
          memberId:sessionStorage.getItem('memberId'),
          imageId:this.providerInfo.OrganisationImages[index].organisationImageId
        }
        // this.spinner.show();
        this.providerInfo.OrganisationImages.splice(index, 1);
        this._apiService.removeOrganizationImage(payLoad).subscribe((res: any) => {
          this.viewUserProfileBYURL(this.QueryParamId);
          this.spinner.hide();
        });
    }
}


  initializeRecaptcha(): void {
    const recaptchaVerifier = new firebase.auth.RecaptchaVerifier('profilerecaptcha', {
      size: 'invisible',
      callback: (response) => {
        console.log('reCAPTCHA resolved:', response);
      },
      'expired-callback': () => {
        console.log('reCAPTCHA expired');
      },
    });

    this.verificationResult = null;
  }

  updateGender(): void {
    
    if (this.selectedGender) {
      this.userProfileObj.gender = this.selectedGender;
    } else {
      console.warn('Please select a gender before updating.');
    }

    this.userProfileObj.memberId = Number(sessionStorage.getItem('memberId'));
    this.userProfileObj.token = sessionStorage.getItem('token');
    // this.userProfileObj.countryCode = this.cntrycode;
    this._apiService.UpdateUserProfile(this.userProfileObj,this.blob).subscribe((res:any)=>{
      if (res.status == 200) {
        this._alertService.success(res.resultUpdateProfile.outputResult);
        this._apiService.sendUpdate(this.userProfileObj);
        this.profileCompletionStatus();
      } else {
        this._alertService.error('Error occurred !!');
      }
    })
  }

  checkLength(event: any): void {
    this.remainingCharacters = 1200 - this.userDescription.length;
    this.removeCharactersCount = this.userDescription.length - 1200;
    if (this.remainingCharacters == 0) {
      this.remainingCharacters = 0;
      this.overflowText = "You have reached the maximum character(1200 characters) limit in description"
      // this._alertService.error('You have reached the maximum character limit in description.');
    } else {
      this.overflowText = "";
    }
    this.cdr.detectChanges();
  }


  editProfile(){
    this.isProfileEdit = true;
    this.breadData4 = "Edit Profile";
  }

  onPhotoSelected(event: any) {
    const file = event.target.files[0]; 
    const ext = '.png'
    if (file) {
      const reader = new FileReader();
      reader.onload = (e: any) => {
        this.imgURL = e.target.result; 
        // this.spinner.show();
        fetch(this.imgURL)
              .then(res => res.blob())
              .then(blob => {
                const fd = new FormData();
                fd.append('image', blob, 'filename');
                this.extn = ext;
                this.blob = blob;
                this.userInfo.imagePath = this.imgURL;
                this.image = this.imgURL;
                this.imgURL = '';
                this.imagetext = null;
                this.spinner.hide();
                this.updateProfilePicture();
              }).catch((err)=>{
                this.spinner.hide();
              });
              
      };
      reader.readAsDataURL(file); 
    }
  }

  updateProfilePicture(){
    this.userProfileObj.fileName = this.providerInfo.fname + this.providerInfo.lname + '.png';
    this.userProfileObj.memberId = Number(sessionStorage.getItem('memberId'));
    this.userProfileObj.token = sessionStorage.getItem('token');
    // this.spinner.show();
    this._apiService.UpdateUserProfile(this.userProfileObj,this.blob).subscribe((res:any)=>{
      if (res.status == 200) {
        this._alertService.success(res.resultUpdateProfile.outputResult);
        this._apiService.sendUpdate(this.userProfileObj);
        this.setProfilePicture();
        this.profileCompletionStatus();
      } else {
        this.spinner.hide();
        this._alertService.error('Error occurred !!');
      }
    })
  }

  setProfilePicture() {
    this._apiService.GetUserActivateStatus(
      Number(sessionStorage.getItem('memberId')),
      this.QueryParamId,
      sessionStorage.getItem('token')
    ).subscribe(res => {
      this.profileImageUrl = res;
      this.profileImageUrl = this.profileImageUrl.GetUserProfileInformationResult.resultUserProfileInfo.profileImageUrl;
      this.spinner.hide();
      this.cdr.detectChanges(); 
      window.location.reload();
    });
  }

  countrychange(country,cntrycode)
  {
    this.country=country;
    this.cntrycode=cntrycode;
  }

  handleClick() {
    document.getElementById('upload-file').click();
  }

  saveData(value){
    this.userProfileObj.memberId = Number(sessionStorage.getItem('memberId'));
    this.userProfileObj.token = sessionStorage.getItem('token');

    if(value === 'organization'){
      this.organizationEdit = false;
      this.editedOrgValue = this.userOrganizationValue  == '' ? ' ': this.userOrganizationValue;;
      this.userProfileObj.organisationName = this.editedOrgValue;
      this.providerInfo.organisationName = this.editedOrgValue;
    } else if (value === 'address') {
      this.userProfileObj.fullAddress = this.localAddress;
      this.userProfileObj.countryCode = sessionStorage.getItem('countryCode') == 'IN' ? '+91' : '+1';
      this.userProfileObj.country_id = parseInt(sessionStorage.getItem('countryId'));
      this.addressEdit = false;
      this.editedAddressValue = this.localAddress;
      this.userProfileObj.fullAddress = this.editedAddressValue;
      this.providerInfo.fullAddress =  this.editedAddressValue;
    } else if(value === 'description') {
      this.descriptionEdit = false;
      this.editedUserDescription = this.userDescription == '' ? ' ': this.userDescription;
      this.userProfileObj.profileDescription = this.editedUserDescription;
      this.providerInfo.profileDescription = this.editedUserDescription;
    } else if (value === 'mobile') {
      if (this.userMobile && this.userMobile.length === 10) {
        if (this.userMobile === this.phoneNumber) {
          this.otpsent = false;
        } else {
          this.mobileEdit = false; 
          this.editedMobileNumber = this.userMobile;
          this.userProfileObj.mobile = this.editedMobileNumber;
          this.providerInfo.mobile = this.editedMobileNumber;
          this.phoneNumber = this.userProfileObj.mobile;
          this.userProfileObj.countryCode = this.cntrycode;
          const num = this.cntrycode + this.userProfileObj.mobile;
          this.phno = num;
        }
      } else {
        alert('Invalid mobile number. Please enter a 10-digit number.');
      }
    } else if(value == 'dateOfBirth'){
      this.dateOfBirthEdit = false;      
      if(this.dateOfBirthRangeValidator(this.dateOfBirthValue)){
        this.editedDateOfBirth = this.dateOfBirthValue;
        this.userProfileObj.dateOfBirth = this.editedDateOfBirth;
        this.providerInfo.dateOfBirth = this.editedDateOfBirth;
      } else {
        this.editedAddressValue = this.providerInfo.dateOfBirth;
        this._alertService.error('You must be at least 10 years old to register.');
        return;
      }
    } else if (value === 'email') {
      this.emailEdit = false;
      this.editedEmailAddress = this.userEmail  == '' ? ' ': this.userEmail;;
      this.userProfileObj.email = this.editedEmailAddress;
      this.providerInfo.email = this.editedEmailAddress;
    } else if (value === 'name') {
      this.fnameEdit = false;
      this.editedFName = this.userFName;
      this.userProfileObj.fname = this.editedFName;
      this.providerInfo.fname = this.editedFName;
      this.lnameEdit = false;
      this.editedLName = this.userLName;
      this.userProfileObj.lname = this.editedLName;
      this.providerInfo.lname = this.editedLName;
    } else if (value === 'photo') {
      this.photoEdit = false;
    }  
    this._apiService.UpdateUserProfile(this.userProfileObj,this.blob).subscribe((res:any)=>{
      if (res.status == 200) {
        if(res.resultUpdateProfile.profileImageUrl == ""){
          this.imagetext = res.resultUpdateProfile.fname?.charAt(0)?.toUpperCase() + res.resultUpdateProfile.lname?.charAt(0)?.toUpperCase();
        }
        this._alertService.success(res.resultUpdateProfile.outputResult);
        setTimeout(() => {
          if(res.resultUpdateProfile.outputResult == "Location cannot be updated without a phone number."){
            window.location.reload();
          }
        }, 500);
        this._apiService.sendUpdate(this.userProfileObj);
        this.profileCompletionStatus();
      } else {
        this._alertService.error('Error occurred !!');
      }
    })
  }

  onInputChange(event: any, type: string): void {
    const regex = /^[a-zA-Z ]*$/; 

    if (!regex.test(event.target.value)) {
        event.target.value = event.target.value.replace(/[^a-zA-Z ]/g, '');
    }

    if (type === 'fname') {
        this.userFName = event.target.value;
    } else if (type === 'lname') {
        this.userLName = event.target.value;
    }
}


  editProfileDone(){
    this.isProfileEdit = false;
    window.location.reload();
  }

  onMobileInputChange(event: any) {
    event.target.value = event.target.value.replace(/[^0-9]/g, '');
    if (event.target.value.length == 10) {

      let details = {
        email: this.providerInfo.email !== "" ? this.providerInfo.email : "",
        mobile: event.target.value,
        countryCode : this.cntrycode
      }
      
      this._apiService.checkMobileNum(details).subscribe(res => {
        if (res != undefined && res != null) {
          if (res.IsUserExistsResult.status == 200) {
            if (res.IsUserExistsResult.IsUserExists == "User exists") {
              this.otpsent = false;
              this.userProfileObj.mobile = event.target.value;
              if(details.countryCode=='+1' || details.countryCode=='1'){
                this.country='usa';
                this.userProfileObj.countryCode="+1";
              }else{
                this.country='india';
                this.userProfileObj.countryCode="+91";
              }
            } else {
              this.otpsent = true;
            }
          }
        }
      });

    }
  }  

  editUserProfile(type){
    if (type === 'organization'){
      this.organizationEdit = true;
      this.userOrganizationValue = this.providerInfo.organisationName;
    } else if (type === 'address') {
      this.addressEdit = true;
      this.userAddress = this.providerInfo.fullAddress;
    } else if(type === 'description') {
      this.descriptionEdit = true;
      this.userDescription = this.providerInfo.profileDescription;
    } else if(type === 'mobile') {
      this.mobileEdit = true;
      this.userMobile = this.phoneNumber;
    } else if(type === 'email') {
      this.emailEdit = true;
      this.userEmail = this.providerInfo.email;
    } else if(type === 'name') {
      this.fnameEdit = true;
      this.userFName = this.providerInfo.fname;
      this.lnameEdit = true;
      this.userLName = this.providerInfo.lname;
    } else if (type === 'skils'){
      this.skillsEdit = true;
    } else if(type === 'dateOfBirth') {
      this.dateOfBirthEdit = true;
      this.dateOfBirthValue = this.providerInfo.dateOfBirth;
    } else if(type === 'photo') {
      this.photoEdit = true;
    }
  }

  saveUserProfile(type){
    if(type === 'organization'){
      this.organizationEdit = false;
      this.editedOrgValue = this.userOrganizationValue;
      this.userProfileObj.organisationName = this.editedOrgValue;
      this.providerInfo.organisationName = this.editedOrgValue;
    } else if (type === 'address') {
      this.addressEdit = false;
      this.editedAddressValue = this.localAddress;
      this.userProfileObj.fullAddress = this.editedAddressValue;
      this.providerInfo.fullAddress =  this.editedAddressValue;
    } else if(type === 'description') {
      this.descriptionEdit = false;
      this.editedUserDescription = this.userDescription;
      this.userProfileObj.profileDescription = this.editedUserDescription;
      this.providerInfo.profileDescription = this.editedUserDescription;
    } else if (type === 'mobile') {
      this.mobileEdit = false;
      this.editedMobileNumber = this.userMobile;
      this.userProfileObj.mobile = this.editedMobileNumber;
      this.providerInfo.mobile = this.editedMobileNumber;
    } else if (type === 'email') {
      this.emailEdit = false;
      this.editedEmailAddress = this.userEmail;
      this.userProfileObj.email = this.editedEmailAddress;
      this.providerInfo.email = this.editedEmailAddress;
    } else if (type === 'name') {
      this.fnameEdit = false;
      this.editedFName = this.userFName;
      this.userProfileObj.fname = this.editedFName;
      this.providerInfo.fname = this.editedFName;
      this.lnameEdit = false;
      this.editedLName = this.userLName;
      this.userProfileObj.lname = this.editedLName;
      this.providerInfo.lname = this.editedLName;
    } else if (type === 'dateOfBirth') {
      this.dateOfBirthEdit = false;
      this.editedDateOfBirth = this.dateOfBirthValue; 
      this.userProfileObj.dateOfBirth = this.editedDateOfBirth;
      this.providerInfo.dateOfBirth = this.editedDateOfBirth;
    }
  }

  onOrganizationInputChange(event: any) {
    this.userOrganizationValueChanged = true;
  }
  

  cancelEditing(type) {
    if (type === 'organization') {
      if (!this.userOrganizationValueChanged) {
        this.organizationEdit = false;
        this.userOrganizationValue = this.editedOrgValue;
      }
      this.userOrganizationValueChanged = false;
    }else if (type === 'address') {
      this.addressEdit = false;
      this.userAddress = this.editedAddressValue;
    } else if(type === 'description') {
      this.descriptionEdit = false;
      this.userDescription = this.editedUserDescription;
    } else if(type === 'mobile') {
      this.mobileEdit = false;
      this.userMobile = this.editedMobileNumber;
    } else if(type === 'email') {
      this.emailEdit = false;
      this.userEmail = this.editedEmailAddress;
    } else if(type === 'name') {
      this.fnameEdit = false;
      this.userFName = this.editedFName;
      this.lnameEdit = false;
      this.userLName = this.editedLName;
    } else if (type === 'dateOfBirth') {
      this.dateOfBirthEdit = false;
      this.userDateOfBirth = this.editedDateOfBirth;
    } else if (type === 'photo') {
      this.photoEdit = false;
    }
  }

  

  safeYoutubeUrl: SafeResourceUrl | null = null;

  setSafeYoutubeUrl(url): void {
      if (!this.safeYoutubeUrl) {
          let embedUrl = this.getYoutubeEmbedUrl(url);
          this.safeYoutubeUrl = this.getSafeYoutubeUrl(embedUrl);
          this.cdr.detectChanges();
      }
  }

  getYoutubeEmbedUrl(youtubeUrl: string): string | null {
    const regex = /(?:https?:\/\/)?(?:www\.)?(?:youtube\.com\/(?:[^\/\n\s]+\/\S+\/|(?:v|e(?:mbed)?)\/|\S*?[?&]v=|shorts\/)|youtu\.be\/)([a-zA-Z0-9_-]{11})/;
    const match = youtubeUrl.match(regex);

    if (match && match[1]) {
        return `https://www.youtube.com/embed/${match[1]}`;
    }
    return null;
}


  getSafeYoutubeUrl(url): SafeResourceUrl {
    const unsafeUrl = url;
    try {
      return this.sanitizer.bypassSecurityTrustResourceUrl(unsafeUrl);
  } catch (e) {
      console.error('Error creating safe YouTube URL:', e);
      return this.sanitizer.bypassSecurityTrustResourceUrl('https://www.youtube.com/embed/default-video-id');
  }
  
}

toggleProfile(event: any): void {
  this.active = event.target.checked;

  const obj: any = {
    memberId: sessionStorage.getItem('memberId'),
    isActive: this.active ? 'Y' : 'N',
    token: this._apiService.token,
  };
  this._apiService.ProfileTurnOnOff(obj).subscribe(
    (res) => {
      try {
        if (res.resultModel.status == 200) {
          const message = this.active
            ? 'Your Profile is active'
            : 'Your Profile is Inactive';
          this._alertService.success(message);
        } else {
          this._alertService.error('Error Occurred !!');
        }
      } catch (err) {
        this.handleExceptionLog(err);
      }
    },
    (err) => {
      this.handleExceptionLog(err);
    }
  );
}

  private handleExceptionLog(err: any): void {
    const obj = {
      ErrorMessage: err,
    };
    this._apiService.ExceptionLog(obj).subscribe((res) => {
    });
  }

  private updateCanonicalLink(): void {
    // this.searchService.updateCanonicalLink(window.location.href);
  }

  viewProviderProfileBYURL(p) {

    const payLoad: any = {};
    payLoad.memberId = sessionStorage.getItem('memberId') || 0 != 0;
    payLoad.providerMemberId = p;
    if (payLoad.memberId == false) {
      payLoad.memberId = 0;
      payLoad.token = '';
    } else {
      payLoad.token = this._apiService.token;
    }
    // this.updateCanonicalLink();

    // this.spinner.show();
    this._apiService.getProviderProfile(payLoad).subscribe((res: any) => {

      this.spinner.hide();

      if (res.status == 200) {
        try {
          this.providerInfo = res.Registration;
          this.image = this.providerInfo.profileImageUrl ? this.providerInfo.profileImageUrl : this.providerInfo.imagePath;
          if(this.image != null && this.image != undefined && this.image != ''){ 
            this.imageValidationService.validateImage(this.image).subscribe((isValid) => {
              this.providerImageValidated = isValid;
              if(!isValid){
                this.imagetext = this.providerInfo.fname.charAt(0).toUpperCase() + this.providerInfo.lname.charAt(0).toUpperCase();
              } else {
                this.imagetext = null;
              }
            },
            error => {
              this.providerImageValidated = false;
            });
           }
          this.PrimaryService = res.Registration.Services[0].userService;
          this.phoneNumber = this.providerInfo.mobile;
          this.descriptionAdd();
          this.addMetaTags();
          res.Registration.Services.forEach(element => {
            this.services.push(element);
          });
          this.providerInfo.mobile = this.providerInfo.mobile.replace(/(.{1,5})$/, function (res) {
            return Array(res.length + 1).join("X");
          });
          this.providerInfo.OrganisationImages.forEach(image => {
            var obj = {
              image: image.orgImagePath,
              thumbImage: image.orgImagePath,
              alt: 'Orgnisation Image',
              // title:'Orgnisation Image',
            }
            this.imageObject.push(obj);
          })

          if (this.providerInfo.OrganisationImages.length == 6) {
              this.isAddPhotos = true;
          } else {
              this.isAddPhotos = false;
          }
        }
        catch {
          err => {
            var obj = {
              ErrorMessage: err
            }
            this._apiService.ExceptionLog(obj).subscribe(res => {

            });

          }
        }
      } else if (res.status == 404) {
        window.scrollTo(0, 0)
        this._alertService.success('logging out');
        sessionStorage.clear();
        let data = {
          token: this._apiService.token
        }
        this._apiService.sendUpdate(data);
        this.router.navigate(["/"]).then(() => {
          window.location.reload();
        })
      }
      ////console.log(ser);
    })

  }

  fileChangeEvent(event: any): void {

    try {
      this.ImgValidate = true;
      if (this.ImgValidate === true) {
        this.imageChangedEvent = event;
        for (let i = 0; i < event.target.files.length; i++) {
          this.selectedFile = event.target.files[i];
          var ext = 'png';
          var reader = new FileReader();
          this.imagePath = event.target.files;
          reader.readAsDataURL(event.target.files[i]);
          reader.onload = (_event) => {
            this.imgURL = reader.result;
            fetch(this.imgURL)
              .then(res => res.blob())
              .then(blob => {
                const fd = new FormData();
                fd.append('image', blob, 'filename');
                this.extn = ext;
                this.blob = blob;
                this.userInfo.imagePath = this.imgURL;
                this.image = this.imgURL;
                this.imgURL = '';
                this.imagetext = null;
              });
          }
        }
      } else {
        this._alertService.error(this.ImgValidate)
      }
    } catch (error) {
      throw error;
    }

  }
  
  addAttachment(event: any,value): void {
    try {
      this.ImgValidate = true;
      if (this.ImgValidate === true) {
        this.imageChangedEvent = event;
        for (let i = 0; i < event.target.files.length; i++) {
          this.selectedFile = event.target.files[i];
          var ext = '.png';
          var reader = new FileReader();
          this.imagePath = event.target.files;
          reader.readAsDataURL(event.target.files[i]);
  
          reader.onload = (_event) => {
            var img: any = reader.result;
            fetch(img)
              .then(res => res.blob())
              .then(blob => {
                const fd = new FormData();
                fd.append('image', blob, 'filename');
                this.extn = ext;
                this.blob = blob;
  
                let payLoad: any = {
                  memberId: sessionStorage.getItem('memberId'),
                  organisationImageName: 'bussiness_file',
                  fileType: this.extn,
                };
  
                this._apiService.uploadOrganisationImages(payLoad, blob).subscribe((res: any) => {
                  this.zone.run(() => {
                    this.viewUserProfileBYURL(this.QueryParamId);
                      window.location.reload(); 
                  });
                });
              });
          };
        }
      } else {
        this._alertService.error(this.ImgValidate);
      }
    } catch (error) {
      throw error;
    }
  }
  
  viewUserProfileBYURL(p) {
    
    let payLoad: any ={
      memberId:p,
      providerMemberId:p,
      token:this._apiService.token
    };
   
    this.userInfo=[];
    // this.spinner.show();
     this._apiService.getUserProfile(payLoad).subscribe((res: any) => {
      try{
        if (res.status == 200 ) {
            this.userInfo = res.Registration;
            sessionStorage.setItem('user', JSON.stringify(res['Registration']));
            if(res.Registration.UserUniqueCode!=null && res.Registration.UserUniqueCode!='' && res.Registration.UserUniqueCode!=undefined){
              this.uniqueId=res.Registration.UserUniqueCode
            }else{
              this.uniqueId='NA';
            }
            this.providerInfo.OrganisationImages = this.userInfo.OrganisationImages;
            this.spinner.hide();
            this._apiService.sendUpdate(this.userInfo);
            this.PrimaryService = res.Registration.Services[0].userService;
            if(res.Registration.OrganisationImages.length==6){
              this.isAddPhotos=true;
            }else{
              this.isAddPhotos=false;
            }
            if (this.userInfo.isActive == 'Y') {
              this.active = true;
            } else if (this.userInfo.isActive == 'N') {
              this.active = false;
            } else {
              this.active = true;
            }
            
            if (this.userInfo.provider == "GOOGLE" || this.userInfo.provider == "FACEBOOK") {
              this.image = this.userInfo.photoUrl;

            } else {
              if (this.userInfo.imagePath != '') {
                if (this.userInfo.imagePath.split("/").splice(-1)[0] !== 'null') {

                  this.image = this.userInfo.profileImageUrl ? this.userInfo.profileImageUrl : this.userInfo.imagePath;
                  if (this.image != null && this.image != undefined && this.image != ''){
                    this.imageValidationService.validateImage(this.image).subscribe((isValid) => {
                      this.providerImageValidated = isValid;
                      if(!isValid){
                        this.imagetext = this.userInfo.fname.charAt(0).toUpperCase() + this.userInfo.lname.charAt(0).toUpperCase();
                      } else {
                        this.imagetext = null;
                      }
                    },
                    error => {
                      this.providerImageValidated = false;
                    });
                  }
                } else {

                  this.imagetext = this.userInfo.fname.charAt(0).toUpperCase() + this.userInfo.fname.charAt(0).toUpperCase();
                }
              } else {

                this.imagetext = this.userInfo.fname.charAt(0).toUpperCase() + this.userInfo.fname.charAt(0).toUpperCase();
              }
            }
            this.services=res.Registration.Services;
          }else if (res.status == 404 ){
            this.spinner.hide();
            this._alertService.success('logging out');
            sessionStorage.clear();
            let data={
              token:this._apiService.token
            }
            this._apiService.sendUpdate(data);
            this.router.navigate(["/"]).then(()=>{
              window.location.reload();
            })
          }else{
            this.spinner.hide();
            this._alertService.error('Error Occured !!')
          }
      }
      catch{ err=>{
        var obj={
          ErrorMessage:err
        }
        this._apiService.ExceptionLog(obj).subscribe(res => {
         
        });

      }  
    }
    })

  }

  verifyuser() {
    let user : any = JSON.parse(sessionStorage.getItem('user'));    
    this.loggedIn = user != null;
    let member = sessionStorage.getItem('memberId');
    if (member == '' || member == undefined || member == null) {
      this.display = 'block';
    }
    let providerNum = this.userActivation.GetUserProfileInformationResult.resultUserProfileInfo.mobile;
    if ((providerNum == '' || providerNum == undefined || providerNum == null) && this.loggedIn) {
      this._alertService.error('Mobile number is not provided by service provider');
      return;
    } else {
      this.callProfile(this.userActivation.GetUserProfileInformationResult.resultUserProfileInfo)
    }
  }

  openUpdateMobileNumberModal(): void {
    const dialogRef = this.dialog.open(UpdateMobileNumberComponent, {
      width: '300px',
      disableClose: true 
    });

    // Handle dialog close event if needed
    dialogRef.afterClosed().subscribe(result => {
    });
  }

  navigateRating(p) {
    const EncodeURL = btoa(p.memberId);
    this.router.navigate(["/rating"], { queryParams: { q: EncodeURL } });
  }

  myImage() {
    if(!this.isProviderProfile){
      const userData = sessionStorage.getItem('user') !== null ? JSON.parse(sessionStorage.getItem('user')) : {};
      this.imagetext = userData.fname.charAt(0).toUpperCase() + userData.lname.charAt(0).toUpperCase();
    } else {
      this.imagetext = this.providerInfo.fname.charAt(0).toUpperCase() + this.providerInfo.lname.charAt(0).toUpperCase();
    }
  }

  showModal: boolean = false;
  closeModal() {
    this.showModal = false;
  }

  openShareModal() {
    this.showModal = true;
    this.profileUrl = window.location.href;
    const modal = document.getElementById('shareModal');
    if (modal) {
      modal.style.display === 'none' ? modal.style.display = 'block' : modal.style.display = 'none';
    }
  }

  enlargedImage: string | null = null;
  currentIndex: number;

  closeEnlargedView(): void {
      this.enlargedImage = null;
  }

  enlargeImage(imagePath: string, index: number): void {
    this.enlargedImage = imagePath;
    this.currentIndex = index;
  }

  slideImage(direction: string): void {
    if (direction === 'left') {
      this.currentIndex = (this.currentIndex > 0) ? this.currentIndex - 1 : this.providerInfo.OrganisationImages.length - 1;
    } else if (direction === 'right') {
      this.currentIndex = (this.currentIndex < this.providerInfo.OrganisationImages.length - 1) ? this.currentIndex + 1 : 0;
    }
    this.enlargedImage = this.providerInfo.OrganisationImages[this.currentIndex].orgImagePath;
    this.cdr.detectChanges();
  }

  callProfile(data) {
    let user : any = JSON.parse(sessionStorage.getItem('user'));    
    this.loggedIn = user != null;
    if (this.loggedIn) {
      if (user.mobile === '' || user.mobile === null || user.mobile === undefined){
        this.openModal();
        return;
      } 
      this.showContactNo = data.countryCode + " - " + data.mobile;
      this._alertService.info("Please pick up the call and wait while we connect with the Service Provider");
      // this.spinner.show();
      this.showGif = true;
      let payLoad: any = {
        "memberId": +sessionStorage.getItem('memberId'),
        "token": sessionStorage.getItem('token'),
        "deviceType": "web",
        "serviceName": data.Categories[0].userService
      }
      this._apiService.FetchTataTeleDetails(payLoad).subscribe((res1:any) => {
        let payLoad: any = {
          "agent_number": user.mobile,
          "destination_number": data.mobile,
          "get_call_id": 1,
          "caller_id" : res1.TataTeleDetails.param4.slice(2)
        }
        let authToken = res1.TataTeleDetails.authorizationToken;
        sessionStorage.setItem("authTokenCall",authToken);
        this._apiService.ClickToCall(payLoad,authToken).subscribe((res2:any) => {
          if(res2.success == false){
            this._alertService.error("Call missed by user");
            this.spinner.hide();
            this.showGif = false;
            let payload: any = {
              "callerId" : sessionStorage.getItem('memberId'),
              "receiverId" : data.memberId,
              "tataTeleResponeCallerId" : '',
              "callerNum" : user.mobile,
              "receiverNum" : data.mobile,
              "agentNum" : res1.TataTeleDetails.param4.slice(2),
              "startDatetime" : this.getCurrentFormattedDateTime(),
              "callStatus" : 'missed',
            }

            this._apiService.InsertCallLogs(payload).subscribe((res3:any) => {
              // this._alertService.success("Call missed by the user")
              this.spinner.hide();
              this.showGif = false;
            }, (error) => {
              console.log(error);
              this.spinner.hide();
              this.showGif = false;
            });
          } else if (res2.call_id != undefined && res2.success === true) {
              sessionStorage.setItem('currentCallId',res2.call_id);
              this._apiService.CheckCallRecords(res2.call_id, authToken).subscribe((res3: any) => {
                if (res3.results.length === 0) {
                  this.inProgress = true;
                  setTimeout(() => {
                    this._alertService.info("If you want to make a another call then please wait for the current call to be over, and refresh the page to make a call.");
                  }, 3000);
                } else {
                  this.inProgress = false; 
                }
              }, (error) => {
                console.log(error);
              });
              // Insert caller details and call id into database
              let payload: any = {
                "callerId" : sessionStorage.getItem('memberId'),
                "receiverId" : `${data.memberId}`,
                "tataTeleResponeCallerId" : res2.call_id,
                "callerNum" : user.mobile,
                "receiverNum" : data.mobile,
                "agentNum" : res1.TataTeleDetails.param4.slice(2),
                "startDatetime" : this.getCurrentFormattedDateTime(),
                "callStatus" : res2.hasOwnProperty('call_id') ? 'Outgoing' : 'missed',
              }

              this._apiService.InsertCallLogs(payload).subscribe((res3:any) => {
                this._alertService.success("Call Connected Successfully")
                this.spinner.hide();
                this.showGif = false;
              }, (error) => {
                console.log(error);
                this.spinner.hide();
                this.showGif = false;
              });

          }
        }, (error) => {
          console.log(error);
          this.spinner.hide();
          this.showGif = false;
          if(error.status === 504 || error.status === 503){
            this._alertService.error("Call missed by the user")
            let payload: any = {
              "callerId" : sessionStorage.getItem('memberId'),
              "receiverId" : data.memberId,
              "tataTeleResponeCallerId" : '',
              "callerNum" : user.mobile,
              "receiverNum" : data.mobile,
              "agentNum" : res1.TataTeleDetails.param4.slice(2),
              "startDatetime" : this.getCurrentFormattedDateTime(),
              "callStatus" : 'missed',
            }
  
            this._apiService.InsertCallLogs(payload).subscribe((res3:any) => {
              // this._alertService.success("Call missed by the user")
              this.spinner.hide();
              this.showGif = false;
            }, (error) => {
              console.log(error);
              this.spinner.hide();
              this.showGif = false;
            });
          }
        });
      }, (error) => {
        console.log(error);
        this.spinner.hide();
        this.showGif = false;
      });
    } else {
      this.display = 'block';
      this._alertService.error("Please login to make a call, redirecting to login page");
      setTimeout(() => {
        sessionStorage.setItem('redirectProviderProfile', 'true');
        this.router.navigate(['/login']).then(() => {
          window.location.reload();
        });
      }, 2000);
    }
  }

  openModal(): void {
    const modal = this.el.nativeElement.querySelector('#viewPhoneNumberModal');
    this.renderer.addClass(modal, 'show');
    this.renderer.setStyle(modal, 'display', 'block');
    document.body.classList.add('modal-open');
    document.body.classList.add('no-backdrop');
  }

  goToProfile():void{
    const modal = this.el.nativeElement.querySelector('#viewPhoneNumberModal');
    this.renderer.setStyle(modal, 'display', 'none');
    this.renderer.removeClass(modal, 'show');
    let user = JSON.parse(sessionStorage.getItem('user'))
    let id = user.memberId;
    let username = user.fname.toLowerCase() + user.lname.toLowerCase()
    username = username.replace(/\s+/g, ' ');
    username = username.replace(/ /g, '-');
    this.router.navigate(['/profile', `${username}-${id}`]).then(()=>{
      window.location.reload();
    });
  }

  getCurrentFormattedDateTime(): string {
    const currentDate = new Date();
  
    const year = currentDate.getFullYear();
    const month = String(currentDate.getMonth() + 1).padStart(2, '0'); // Month is zero-based
    const day = String(currentDate.getDate()).padStart(2, '0');
    const hours = String(currentDate.getHours()).padStart(2, '0');
    const minutes = String(currentDate.getMinutes()).padStart(2, '0');
    const seconds = String(currentDate.getSeconds()).padStart(2, '0');
    const milliseconds = currentDate.getMilliseconds().toString().padStart(3, '0');
  
    const formattedDateTime = `${year}-${month}-${day} ${hours}:${minutes}:${seconds}.${milliseconds}`;
  
    return formattedDateTime;
  }

  goToHome(): void {
    this.router.navigate(['/']).then(() => {
      window.location.reload();
    });
  }

}