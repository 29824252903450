import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, Subject } from 'rxjs';
import { NgxSpinnerService } from 'ngx-spinner';
import { ServerApisService } from './server-apis.service';
import { GoogleApisService } from './google-apis.service';
import { GoogleTagManagerService } from './google-tag-manager.service';
import { Title } from "@angular/platform-browser";
import { Meta } from "@angular/platform-browser";
import { Constants } from '../constants/constant';
import { StaticRoutesService } from './static-routes.service';
import { SitemapService } from './sitemap.service';
@Injectable({
  providedIn: 'root',
})
export class SearchService {
  private showPopupSubject = new Subject<void>();
  private closePopupSubject = new Subject<void>();
  sessionStorageSearchObj: any;
  lstAllServices: any;
  constructor(private sitemapService:SitemapService,private staticRoutes: StaticRoutesService, private _apiService: ServerApisService, private title: Title, private meta: Meta, private spinner: NgxSpinnerService, private _googleApi: GoogleApisService, private gtmService: GoogleTagManagerService) { }
  private currentLocationSubject = new BehaviorSubject<{ latitude: number, longitude: number }>({
    latitude: 0,
    longitude: 0
  });
  private userChangedLocationSubject = new BehaviorSubject<{ latitude: number, longitude: number }>({
    latitude: 0,
    longitude: 0
  });
  allStaticRoutes = this.staticRoutes.getStaticRoutes();
  private searchDataSubject = new BehaviorSubject<any>({});
  private searchResultsSubject: BehaviorSubject<any[]> = new BehaviorSubject<any[]>([]);
  public searchResults$: Observable<any[]> = this.searchResultsSubject.asObservable();
  public isStaticRoute = this.allStaticRoutes.some(route => window.location.pathname.includes(route));

  getuserChangedLocationSubject() {
    return this.userChangedLocationSubject.asObservable();
  }

  userChangedUpdateLocation(latitude: number, longitude: number) {
    this.userChangedLocationSubject.next({ latitude, longitude });
  }

  getCurrentLocationSubject() {
    return this.currentLocationSubject.asObservable();
  }

  currentUpdateLocation(latitude: number, longitude: number) {
    this.currentLocationSubject.next({ latitude, longitude });
  }

  setSearchData(data: any) {
    this.searchDataSubject.next(data);
  }

  getSearchData(): Observable<any> {
    return this.searchDataSubject.asObservable();
  }

  showPopup() {
    this.showPopupSubject.next();
  }

  closePopup() {
    this.closePopupSubject.next();
  }

  getClosePopupSubject() {
    return this.closePopupSubject.asObservable();
  }

  getShowPopupSubject() {
    return this.showPopupSubject.asObservable();
  }

  fetchHireAndExpert(searchData: any, pageNo): Observable<any[]> {

    if (searchData.categoryid) {
      this._apiService.GetChildProfessionData(searchData.categoryid).subscribe((res: any) => {
        const professionData = res.GetChildProfessionResult.resultServiceProfessions[0];
        // this.updateCanonicalLink(window.location.href);
        if (professionData.title && professionData.description && professionData.keywords) {
          //this.title.setTitle(professionData.title);
          //this.meta.updateTag({ name: 'description', content: professionData.description });
          //this.meta.updateTag({ name: 'title', content: professionData.title });
          //this.meta.updateTag({ name: 'keywords', content: professionData.keywords });
          //this.meta.updateTag({ name: 'og:title', content: professionData.title });
          //this.meta.updateTag({ name: 'og:description', content: professionData.description });
          //this.meta.updateTag({ name: 'twitter:title', content: professionData.title });
          //this.meta.updateTag({ name: 'twitter:description', content: professionData.description });
        }
      });
    }

    if ((sessionStorage.getItem('loggedIn') == undefined || sessionStorage.getItem('loggedIn') == null || sessionStorage.getItem('loggedIn') == 'false') && !this.isStaticRoute) {
      setTimeout(() => {
        this.showPopup();
      }, 15000);
    }
    const distance = searchData.filterDistance * 1000;
    const sessionStorageCountryCode = sessionStorage.getItem('countryCode');
    if (searchData.toCorrdinates == "0,0") {
      searchData.toCorrdinates = sessionStorage.getItem('latlng');
    }
    const payLoad: any = {
      currentPage: pageNo,
      memberId: +sessionStorage.getItem('memberId') || 0,
      serviceName: searchData.filterName,
      deviceType: 'Website',
      token: sessionStorage.getItem('token'),
      toCoordinates: sessionStorage.getItem('latlng'),
      locality: sessionStorage.getItem('locality'),
      countryCode: sessionStorageCountryCode,
      range: distance,
      categoryId: searchData.categoryid,
    };

    if (searchData.toCorrdinates == null || searchData.toCorrdinates == undefined || searchData.toCorrdinates == "") {
      return;
    }
    this.spinner.show();
    this._apiService.GetHireAndExpert(payLoad).subscribe(
      (res) => {
        this.spinner.hide();
        if (res.GetHireAnExpertResult.GetCategories.length > 0) {
          this.searchResultsSubject.next(res.GetHireAnExpertResult.GetCategories);
          sessionStorage.setItem('totalSearchCount', res.GetHireAnExpertResult.RecordCount)
        } else {
          this.searchResultsSubject.next([]);
        }
      },
      (error) => {
        this.spinner.hide();
        console.log(error);
      }
    );
    let url = window.location.pathname;
    url = url.replace(/\s+/g, '-');
    if(!this.isStaticRoute && !url.includes('?') && !url.includes('%') && !url.includes('not-found') && url !== '/'){ 
      this.sitemapService.storeSitemapToSearchDB(url.toLowerCase());
    }
    
    
    this.gtmService.pushEvent('searchEvent', {
      'searchEvent': searchData,
      'memberId': sessionStorage.getItem('memberId') || '0'
    });

    return this.searchResultsSubject;
  }

  updateCanonicalLink(newHref: string) {
    const existingLink = document.querySelector('link[rel="canonical"]');
    if (existingLink) {
      existingLink.setAttribute('href', newHref);
    } else {
      const link = document.createElement('link');
      link.setAttribute('rel', 'canonical');
      link.setAttribute('href', newHref);
      document.head.appendChild(link);

    }
  }

  crossBrowserSearch(lstAllServices, pageNo) {
    let searchData = sessionStorage.getItem('searchData') ? JSON.parse(sessionStorage.getItem('searchData')) : null;
    if (searchData != null && searchData != undefined && searchData != "") {
      this.fetchHireAndExpert(searchData, pageNo)?.subscribe((res: any) => { });
    }
    let sessionStorageSearchObj: any = {};
    let url = window.location.pathname;
    const parts = url.split('/');
    if (parts.length > 1 && !this.isStaticRoute) {
      sessionStorageSearchObj.filterName = parts[2]?.replace(/-/g, ' ');
      if (lstAllServices.length > 0) {
        lstAllServices.forEach(element => {
          if (element.subProfessionCategoryName.toLowerCase() == sessionStorageSearchObj?.filterName?.toLowerCase()) {
            sessionStorageSearchObj.categoryid = element.subProfessionsCategoryId;
          }
        });
      }
      sessionStorageSearchObj.toCorrdinates = sessionStorage.getItem('latlng');
      this._googleApi.getCurrentGeoAddress({ latlng: sessionStorageSearchObj.toCorrdinates }).subscribe(res => {
        for (let i = 0; i < res.results.length; i++) {
          for (let m = 0; m < res.results[i].types.length; m++) {
            const ele = res.results[i].types[m];
            if (ele == "locality") {
                sessionStorage.setItem('locality', res.results[i].address_components[0].long_name);
            } else if (ele == "country") {
              sessionStorage.setItem('countryCode', res.results[i].address_components[0].short_name);
            }
          }
        }
        sessionStorageSearchObj.localAddress = res.results[0].formatted_address;
        sessionStorageSearchObj.filterDistance = Constants.DefaultRangeDistance;
        const pasteURL = sessionStorage.getItem('pasteURL');
        if (pasteURL != null && pasteURL != undefined && pasteURL != "") {
          if (pasteURL == "true") {
            sessionStorageSearchObj.filterDistance = Constants.DefaultRangeDistance;
            sessionStorage.setItem('pasteURL', "false");
          }
        }
        sessionStorage.setItem('location', sessionStorageSearchObj.localAddress);
        sessionStorage.setItem('searchData', JSON.stringify(sessionStorageSearchObj));
        this.fetchHireAndExpert(sessionStorageSearchObj, pageNo).subscribe((res: any) => { });
      })
    }

  }
}
